import React from "react";
import styled from "styled-components";

import culture from "../../../assets/careers/culture.png";
import spaceship from "../../../assets/careers/spaceship.png";
import star from "../../../assets/careers/star.png";
import tech from "../../../assets/careers/tech.png";

const AssetImage = styled.img`
  height: 100px;
  margin-bottom: 10px;
`;

const Grid = styled.div`
  display: grid;

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }

  @media only screen and (min-width: 700px) {
    grid-column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 275px;
  }
`;

const Aspect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
`;

const Description = styled.div`
  line-height: 30px;
`;

const Info = () => {
  return (
    <Grid>
      <Aspect>
        <AssetImage src={culture} />
        <Title>Open culture, full of passion</Title>
        <Description>
          Life is what happens when you&apos;re busy working. We make sure to
          enjoy it and grow together. Be a Cereal Entrepreneur.
        </Description>
      </Aspect>

      <Aspect>
        <AssetImage src={tech} />
        <Title>State of the art technology</Title>
        <Description>
          Work with extremely talented people and apply the latest research
          results to real-world problem. Are you ready to revolutionize the
          technology?
        </Description>
      </Aspect>

      <Aspect>
        <AssetImage src={spaceship} />
        <Title>Backed by visionary VC, fast growing</Title>
        <Description>
          We are growing fast with the support from top tier venture
          capitalists and experienced industry leaders to bring the new
          technology to market.
        </Description>
      </Aspect>

      <Aspect>
        <AssetImage src={star} />
        <Title>Make real impact</Title>
        <Description>
          We trust and empower you to move fast and build the best technology
          and product for our happy customers - as a team.
        </Description>
      </Aspect>
    </Grid>
  );
};

export default Info;
