import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import Info from '../components/pages/careers/Info';
import {
  CenteredMainDescription,
  CenteredMainTitle,
} from '../components/intro/common';
import Positions from '../components/pages/careers/positions';

const IntroSection = styled.section`
  position: relative;
  padding: 60px 20px;
`;

const Intro = styled(Container)`
  text-align: center;
`;

const TopBackground = styled.div`
  position: absolute;
  background-image: linear-gradient(180deg, #9396ff 20%, #4e89f9 100%);
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;
  overflow: hidden;
`;

const Description = styled.div`
  margin: 50px auto 50px;
  text-align: center;
  line-height: 30px;
`;

const DescriptionTitle = styled.div`
  font-size: 32px;
  margin-bottom: 15px;
`;

const DescriptionText = styled.div`
  margin-bottom: 10px;
`;

const ApplicationSection = styled.div`
  background: #f4f4f4;
  text-align: center;
  line-height: 30px;
  padding: 20px 0;

  @media only screen and (min-width: 700px) {
    padding: 50px 20px 10px;
  }
`;

const Careers = () => (
  <Layout footerBackgroundColor='#F4F4F4'>
    <SEO
      title='Careers'
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <IntroSection id='intro'>
      <Intro>
        <CenteredMainTitle whiten>Percept AI is growing fast</CenteredMainTitle>
        <CenteredMainDescription whiten>Grow with us</CenteredMainDescription>
      </Intro>
      <TopBackground />
    </IntroSection>

    <Container>
      <Description>
        <DescriptionTitle>
          Road to AI empowered business functions
        </DescriptionTitle>
        <DescriptionText>
          Percept AI was founded with the vision of a future where, by
          leveraging AI technologies, people can be freed from mundane tasks of
          their daily operations to focus more on true innovations. Today
          Percept AI is spearheading toward this future, by building the
          ultimate&nbsp;
          <b>on-demand platform for AI automated business functions</b>.
        </DescriptionText>
      </Description>

      <Info />
    </Container>

    <ApplicationSection>
      <Container>
        <DescriptionTitle>
          Apply today and change how business works by AI
        </DescriptionTitle>
        <DescriptionText>
          We pay competitively and offer all the best startup benefits,
          including insurance, paid vacation, and Work from Home. But more than
          that, we provide a supportive environment where you can do the best
          work of your life, where creativity and personal initiative are
          cherished, where product quality standards are nothing short of
          excellent, and where you have a direct, leading role in delivering
          real values to customers.
        </DescriptionText>

        <Positions />
      </Container>
    </ApplicationSection>
  </Layout>
);

export default Careers;
