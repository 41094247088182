import React from 'react';
import styled from 'styled-components';
import { basePurpleButton } from '../../button';

const Grid = styled.div`
  display: grid;
  grid-column-gap: 30px;

  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Position = styled.div`
  margin-top: 20px;
  background: #ffffff;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media only screen and (min-width: 700px) {
    padding: 30px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 15px;
`;

const Location = styled.div`
  font-size: 16px;
  margin-bottom: 25px;
`;

const ApplyButton = styled.a`
  ${basePurpleButton}
`;

const Positions = () => {
  return (
    <Grid>
      <Position>
        <Title>Senior Product Marketing</Title>
        <Location>Sunnyvale, CA | Contract-to-Hire</Location>
        <ApplyButton
          href='https://apply.workable.com/percept-ai/j/3F09EC82DF/'
          target='_blank'
          rel='noreferrer noopener'
        >
          Apply
        </ApplyButton>
      </Position>
    </Grid>
  );
};

export default Positions;
